import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from '../components/layout'
import Banner from '../components/Banner'
import { AnchorLink } from "gatsby-plugin-anchor-links"
//import Parallax from 'react-rellax'

//import peopleProjects from "../assets/images/people-projects.svg"
//import network from "../assets/images/network.svg"

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Block and Tackle | People Operations"
                    meta={[
                        { name: 'description', content: 'People Operations and HR consultancy' },
                        { name: 'keywords', content: 'hr,people-operations,agency, consultancy' },
                    ]}
                >
                </Helmet>

                <Banner />

                {/* <Parallax speed={7}> */}
                <div id="main">
                <section id="one" className="home-section">
                        <div className="inner grid-wrapper">
                            <div className="col-12">
                                <h3 className="align-center">We are people focused agency experts: mentors, coaches, consultants, and legal professionals. We put a lot into work, let's make the most out of it.
                                </h3>
                            </div>
                            <div className="col-6">
                                <header className="major">
                                    {/* <img src={peopleProjects} alt="people projects icon" className="service-icon"/> */}
                                    <h2>Projects for people</h2>
                                </header>
                                <p>Fast forward five years from now. Your agency is smashing goals you set way back in 2020, and everyone, including you, is loving how things are going. You may know what you need to achieve commercially, or in product development, but do you know how people operations are going to support you achieving your vision? We can help you give your employees the best experience, ensuring they love doing great work with you.</p>
                                <ul className="actions">
                                    <li><AnchorLink to="/about/#contact-2" className="button next alt-dark">Plan a people project</AnchorLink></li>
                                </ul>
                            </div>
                            <div className="col-6">
                                <header className="major">
                                    {/* <img src={network} alt="network icon" className="service-icon"/> */}
                                    <h2>People for projects</h2>
                                </header>
                                <p>We have a sophisticated network of agency people around the globe. Whether you need an extra pair of hands to deliver a particularly gnarly part of a project, or a full blown team for a long term programme, we can bring the right people together. We can connect you with:</p>
                                <ul>
                                    <li>Lead Engineers</li>
                                    <li>Designers</li>
                                    <li>Delivery experts: Scrum, Product and Project Managers</li>
                                </ul>
                                <ul className="actions">
                                    <li><AnchorLink to="/about/#contact-2" className="button next alt-dark">Find your team</AnchorLink></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section id="two" className="alt">
                        <div className="inner">
                            <header className="major">
                                <h2>Who do you want to work with?</h2>
                            </header>
                            <p>People aren't resources. Your team should be made up of people you love working with, and people who love what they are doing with you.</p>
                            <p>We work with teams of all sizes; from solo business owners looking to build a team of people they can rely on, to agencies looking to create an effective and enjoyable learning environment.</p>
                            <ul className="actions">
                                <li><AnchorLink to="/about/#contact-2" className="button alt-dark next">Let's talk</AnchorLink></li>
                            </ul>
                            <ul className="actions">
                                <li><Link to="/about" className="button dark">More about us</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>
            {/* </Parallax> */}

            </Layout>
        )
    }
}

export default HomeIndex