import React from 'react'
import Logo from './Logo'
import { graphql, useStaticQuery } from "gatsby"
import caveRocks from "../assets/images/cave-bottom-combined.png"
import rocksPerson from "../assets/images/orange-chap-solo.png"
//import caveFrontFill from "../assets/images/cave-front-fill-narrow.svg"
//import Parallax from 'react-rellax'

const Banner = () => {
    const data = useStaticQuery(graphql`
        query Images {
        image: file(relativePath: {eq:"cave-large.jpg"})
            {
                id
                childImageSharp {
                fixed {
                    src
                    srcSet
                }
                fluid {
                    src
                    srcSet
                }
                }
            }
        }
    `)

    console.log(data);
    return (
    <section id="banner" className="major">
        <div className="inner home-logo">
            {/* <Parallax speed={1}> */}
                <header className="major">
                    <Logo />
                </header>
            {/* </Parallax> */}
        </div>
        {/* <Parallax speed={3} className="rocks-person"> */}
        <div className="rocks-container">
            <img src={rocksPerson} alt="rocks" className="rocks-person"/>
            <img src={caveRocks} alt="rocks" className="cave-rocks"/>
            <div className="content rocks-content">
                <h1>People operations and HR consultancy</h1>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">How we support your team</a></li>
                </ul>
            </div>
        </div>

        {/* </Parallax> */}

        {/* <div className="rocks-fill"><img src={caveFrontFill} alt="rocks-fill" className="rocks-fill-img rocks-fill"/></div> */}
    </section>
    )
}

export default Banner
/* src/components/Banner.js
src/assets/images/cave-front-fill.svg */